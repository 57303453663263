export const isMobileView = () => {
  const isMobileViewport =
    window.innerWidth < 768 && window.devicePixelRatio < 2;
  const isMobileUserAgent =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const isMobileWebview = /wv/.test(navigator.userAgent);

  const isMobile = isMobileViewport || isMobileWebview || isMobileUserAgent;

  console.log('isMobileView', isMobile);

  return isMobile;
};

export const base64ToUtf8 = (str) => decodeURIComponent(escape(atob(str)));

export const utf8ToBase64 = (str) => btoa(unescape(encodeURIComponent(str)));

export const isValidEmail = (email) => {
  // Basic email regex pattern
  const emailPattern = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g;

  if (!email || typeof email !== 'string') return false;

  if (email.length > 75) return false;

  return emailPattern.test(email.trim());
};
