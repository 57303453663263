/* eslint-disable func-names */
import React, { useState } from 'react';
import clsx from 'clsx';
import Button from './button';
import CalDotComPopup from './calDotComPopup';
import { CONSOLE_BASE_URL } from '../config';

export default function Header() {
  const [isCalDotComPopupOpen, setIsCalDotComPopupOpen] = useState(false);

  return (
    <header
      className={clsx(
        'top-0 px-10 bg-white z-[9]',
        'max-sm:p-4 sm:flex sm:items-center sm:w-full max-md:justify-start',
        'md:block max-md:border-custom-border max-md:fixed max-md:right-0 max-md:left-0 max-md:border-b-0 max-md:shadow-header-shadow max-md:p-[16px]',
        'lg:pt-[40px] max-lg:pt-10 max-lg:mb-6'
      )}
    >
      <div className="flex items-end justify-between max-md:w-full max-md:items-center">
        <img
          src={`${CONSOLE_BASE_URL}/email-assets/plivo-logo.svg`}
          style={{ marginBottom: '-11px', width: '120px', height: '52px' }}
          className="max-md:w-[120px] max-md:h-[30px]"
          alt="Plivo"
        />
        <div className="header-btn flex gap-5 items-center">
          <a
            href="https://www.plivo.com/cx/service/features/chatbot"
            target="_blank"
            rel="noopener noreferrer"
            className="font-poppins text-custom-learn-more text-custom-buttom-14px font-medium max-md:hidden"
          >
            Learn More
          </a>
          <Button
            className="font-poppins text-custom-buttom-14px leading-6 px-4 py-3 max-md:px-3 rounded font-normal max-sm:h-9 max-sm:py-[6px] shadow-lg transition-all duration-300 ease-out hover:shadow-xl hover:scale-[1.02] bg-black text-white hover:bg-gray-900"
            type="button"
            onClick={() => {
              setIsCalDotComPopupOpen(true);
            }}
          >
            Hire Plivo AI
          </Button>
          <CalDotComPopup
            isOpen={isCalDotComPopupOpen}
            onClose={() => setIsCalDotComPopupOpen(false)}
          />
        </div>
      </div>
    </header>
  );
}
