import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function Toast({ show, message, type, onCloseToast }) {
  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        onCloseToast();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [onCloseToast, show]);

  const handleCloseToast = () => {
    onCloseToast(false);
  };

  if (!message || !show) return null;

  return (
    <div
      className={`fixed top-16 right-16 rounded-md px-4 py-3 shadow-lg z-[10000000] ${
        type === 'success'
          ? 'bg-green-500 text-green-50'
          : 'bg-red-500 text-red-50'
      }`}
    >
      <div className="flex items-center justify-between">
        <p>{message}</p>
        <button
          type="button"
          className="ml-4 bg-transparent text-inherit hover:text-white focus:outline-none"
          onClick={handleCloseToast}
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}

Toast.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onCloseToast: PropTypes.func.isRequired,
};

function XIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}

XIcon.propTypes = {
  className: PropTypes.string,
};
XIcon.defaultProps = {
  className: '',
};
