import { CHAT_WIDGET_MOUNT_ID } from '../config';

const injectFunction = (appId, appKey, region, email, shared) => `
(function(d, s, o, f, js, fjs) {
  const origin = '${process.env.REACT_APP_CHAT_WIDGET_ORIGIN}';
  js = d.createElement(s);
  fjs = d.getElementsByTagName(s)[0];
  js.id = o;
  js.src = origin + f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
  js.onload = (e) => (_moduleCCW.init(origin));
}(document, 'script', '_ccw', '/assets/js/widget.js'));

window.__C_CHAT_CONFIG = {
  appInfo: {
    appId: '${appId}',
    appKey: '${appKey}',
    region: '${region}',
    device: 'web',
    appType: 'pwa',
    demo: true,
    showResetConversation: true,
    mountId: '${CHAT_WIDGET_MOUNT_ID}',
    poweredBy: 'Plivo AI',
    shared: ${shared},
  },
  userInfo: {
    email: '${email}',
  },
};
`;

export default injectFunction;
