import './App.css';
import React, { useEffect } from 'react';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import MainApp from './views/main';

function App() {
  useEffect(() => {
    const appInfo = localStorage.getItem('appInfo');
    const app = appInfo ? JSON.parse(appInfo) : null;

    if (app && app.email) {
      const { email } = app;
      posthog.identify(email, { email });
    }
  }, []);

  const posthogOptions = {
    api_host: 'https://d2ugscwbtnj8cv.cloudfront.net',
    person_profiles: 'always',
    record_cross_origin_iframes: true,
    storage: 'session',
    session_recording: {
      maskAllInputs: false,
      recordCrossOriginIframes: true,
      maskInputOptions: {
        password: true,
      },
    },
  };

  return (
    <div className="App">
      <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
        options={posthogOptions}
      >
        <MainApp />
      </PostHogProvider>
    </div>
  );
}

export default App;
