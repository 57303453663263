import React, { useEffect, useState } from 'react';
import axios from 'axios';
import injectFunction from '../utils/script.js';
import Login from './login.jsx';
import ChatApp from './Chat';
import ShareView from './Share';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';
import {
  CHAT_WIDGET_DIV_TAG_ID,
  CHAT_WIDGET_SCRIPT_TAG_ID,
  BYPASS_EMAIL_AUTH,
} from '../config/index.js';
import Toast from '../components/Toast';
import clsx from 'clsx';
import { getAppInfo } from '../utils/service';
export const initializePostHog = (email) => {
  if (email) {
    posthog.identify(email)
  }
  return posthog
}


export default function MainApp() {
  const location = useLocation();
  const navigate = useNavigate();
  const storedApp = localStorage.getItem('appInfo');
  const [email, setEmail] = useState('');
  const [chatScript, setChatScript] = useState(null);
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: '',
  });
  useEffect(() => {
    if (storedApp) {
      if (!['/chat', '/'].includes(location.pathname)) {
        return;
      }
      // Parse the JSON string to an object
      const app = JSON.parse(storedApp);

      setEmail(app.email); // Set email from app info
      initializePostHog(app.email); // Initialize PostHog with email
      const { app_id, app_token, region, email } = app;
      setChatScript(injectFunction(app_id, app_token, region, email));
      if (BYPASS_EMAIL_AUTH) {
        navigate('/' + location.search);
      } else {
        navigate('/chat' + location.search);
      }
    } else {
      if (!['/chat', '/'].includes(location.pathname)) {
        return;
      }
      if (BYPASS_EMAIL_AUTH) {
        fetchAppInfo();
      } else {
        handleClearChatScript();
        navigate('/' + location.search, { replace: true });
      }
    }
  }, [storedApp]);

  const fetchAppInfo = async () => {
    await getAppInfo(email, (response) => {
        if (response.status === 200) {
          const { app_id, app_token, region } = response.data.data;
          const appInfo = { app_id, app_token, region, email };
          setChatScript(injectFunction(app_id, app_token, region, email));
          localStorage.setItem('appInfo', JSON.stringify(appInfo));
          if (!BYPASS_EMAIL_AUTH) {
            navigate('/chat' + location.search);
          } else {
            navigate('/' + location.search);
          }
        } else {
          console.log('Unexpected status code:', response.status);
          setToast({
            show: true,
            message: response.errors.global_error,
            type: 'error',
          });
        }
      }, (error) => {
        console.error('Error fetching appId and appKey:', error);
        setToast({ show: true, message: error.message, type: 'error' });
      }
    );
    setLoader(false);
  };

  //ToDo: error handling needs to be added
  //ToDo: Ensure you execute handleClearChatScript before making api call, example when u come back fro widget screen this scnario will come
  const onScriptInfoSubmit = async (e) => {
    e.preventDefault();
    setLoader(() => true);
    fetchAppInfo();
  };

  useEffect(() => {
    const recievePostMessage = (e) => {
      if (e.origin !== 'https://calendly.com') return;
      const type = e.data.action;
      switch (type) {
        case 'removeChatWidget':
          setChatScript(null);
          handleClearChatScript();
          break;
        default:
          console.log('default : ', type);
      }
    };
    window.addEventListener('message', recievePostMessage);
  }, []);

  const handleClearChatScript = () => {
    const chatScriptTagEle = document.getElementById(CHAT_WIDGET_SCRIPT_TAG_ID);
    const chatScriptTagEleCCW = document.getElementById('_ccw');
    const chatDivTagEle = document.getElementById(CHAT_WIDGET_DIV_TAG_ID);
    chatScriptTagEle &&
      chatScriptTagEle.parentNode?.removeChild(chatScriptTagEle);
    chatDivTagEle && chatDivTagEle.parentNode?.removeChild(chatDivTagEle);
    chatScriptTagEleCCW &&
      chatScriptTagEleCCW.parentNode?.removeChild(chatScriptTagEleCCW);
    setChatScript(null);
  };

  const closeToast = () => {
    setToast({ show: false, message: '', type: '' });
  };

  const isChatView = BYPASS_EMAIL_AUTH ? true : location.pathname.includes('/chat');

  return (
    <>
      <div className="webview w-full min-lg:h-screen relative max-lg:flex max-lg:justify-center max-lg:items-center max-lg:overflow-hidden">
        <div
          className={clsx(
            'left-bg absolute right-[65%] w-full h-[100%] opacity-[0.46] bg-custom-left-bg bg-center max-lg:hidden',
            isChatView && 'max-sm:hidden'
          )}
        ></div>
        <div
          className={clsx(
            'right-bg bg-custom-gradient bg-no-repeat bg-center absolute left-[44%] w-[56%] h-[100%] border-radius-[50%] opacity-[0.4] max-lg:hidden',
            isChatView && 'max-sm:hidden'
          )}
        ></div>
        <div
          className={clsx(
            'bg-mobile absolute w-[100%] opacity-[0.4] top-[35%] h-[65%] bg-custom-bg-mobile bg-no-repeat bg-center max-lg:bottom-[0%] min-lg:hidden max-md:top-[13%]',
            isChatView && 'max-sm:hidden'
          )}
        ></div>
        <Routes>
          <Route
            path="/"
            element={
              !BYPASS_EMAIL_AUTH ? (
                <Login
                  email={email}
                  onEmailChange={setEmail}
                  onSubmit={onScriptInfoSubmit}
                  loader={loader}
                  handleClearChatScript={handleClearChatScript}
                  closeToast={closeToast}
                />
            ) : <ChatApp
                  chatScript={chatScript}
                  setToast={setToast}
                  loader={loader}
                />
            }
          />
          <Route
            path="/chat"
            element={
              <ChatApp
                chatScript={chatScript}
                setToast={setToast}
              />
            }
          />
           <Route
            path="/demo/share/:hash"
            element={<ShareView />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Toast
          message={toast.message}
          type={toast.type}
          show={toast.show}
          onCloseToast={closeToast}
        />
      </div>
    </>
  );
}
