import React from 'react';

const Button = ({
  size = 'm',
  children,
  onClick,
  variant = 'primary',
  className = '',
  type = 'button',
  ...props
}) => {
  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    s: 'px-3 py-1 text-sm',
    sm: 'px-4 py-2 text-sm',
    m: 'px-5 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
    xl: 'px-8 py-4 text-xl',
  };

  const variantClasses = {
    primary:
      'bg-primary-btn text-white hover:bg-primary-btn-hover shadow-lg hover:shadow-xl hover:shadow-custom transition-all duration-150',
    secondary:
      'bg-secondary-btn text-white hover:bg-secondary-btn-hover shadow-lg hover:shadow-xl hover:shadow-custom transition-all duration-150',
    outline:
      'bg-outline-btn text-custom-black hover:bg-outline-btn-hover hover:shadow-xl hover:shadow-custom transition-all duration-150',
    danger: 'bg-danger-btn text-white hover:bg-danger-btn-hover',
  };

  return (
    <button
      type={type}
      className={`rounded font-medium transition-all duration-150 focus:outline-none ${sizeClasses[size]} ${variantClasses[variant]} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
