import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { base64ToUtf8, isValidEmail } from '../../utils/helper';
import Footer from '../../components/footer';
import { AIAssistantLoader } from '../../components/loader';
import { CHAT_WIDGET_MOUNT_ID, CHAT_WIDGET_SCRIPT_TAG_ID, FETCH_APP_INFO_URL } from '../../config';
import axios from 'axios';
import injectFunction from '../../utils/script';
import { CONSOLE_BASE_URL } from '../../config';

const ShareView = () => {
  const { hash } = useParams();
  const [channelInfo, setChannelInfo] = useState(null);
  const chatContainerRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [chatScript, setChatScript] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appInfo, setAppInfo] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: '',
  });

  const handleChatMessageEvent = (event) => {
    const { action, data } = event.data;

    switch (action) {
      case 'loaded':
        console.log('Chat widget loaded successfully');
        setIsLoading(false);
        setShowChat(true);
        break;
      case 'error':
        console.log('Chat widget failed to load: ', data.error);
        setToast({ show: true, message: data.error, type: 'error' });
        setIsLoading(false);
        setShowChat(true);
        break;
      default:
        break;
    }
  }; 

  useEffect(() => {
    window.addEventListener('message', handleChatMessageEvent, false);
    return () => {
      window.removeEventListener('message', handleChatMessageEvent, false);
    };
  }, []);

  useEffect(() => {
    const channeInfo = JSON.parse(base64ToUtf8(hash));
    setChannelInfo(channeInfo);
  }, [hash]);

  useEffect(() => {
    if (!channelInfo?.contactIdentifier) return;
    const hostname = window.location.origin;
    let email = ""
    if (isValidEmail(channelInfo?.contactIdentifier)) {
      email = channelInfo?.contactIdentifier;
    }
    axios
      .post(FETCH_APP_INFO_URL, {
        email,
        hostname,
      })
      .then((response) => {
        if (response.status === 200) {
          const { app_id, app_token , region } = response.data.data;
          const appInfo = { app_id, app_token, region, email };
          setAppInfo(appInfo);
          setChatScript(injectFunction(app_id, app_token, region, email, true));
        } else {
          console.log('Unexpected status code:', response.status);
          setToast({
            show: true,
            message: response.errors.global_error,
            type: 'error',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching appId and appKey:', error);
        setToast({ show: true, message: error.message, type: 'error' });
      })
      .finally(() => {
        setLoader(false);
      });
  }, [channelInfo]);

  useEffect(() => {
    if (!chatScript || document.getElementById(CHAT_WIDGET_SCRIPT_TAG_ID))
      return;

    console.log('Appending chat widget script to document head');
    const script = document.createElement('script');
    script.id = CHAT_WIDGET_SCRIPT_TAG_ID;
    script.innerHTML = chatScript;

    // Append the script to the document head
    document.head.appendChild(script);
    console.log('Script tag appended to head.');
  }, [chatScript]);

  console.log('CHANNEL INFO :  ', channelInfo, chatScript);

  const loadConversationHistory = (data) => {
    if (
      window?._moduleCCW &&
      typeof window._moduleCCW?.CXMessenger?.loadConversationHistory ===
        'function'
    ) {
      // adding a delay to allow the chat widget to load internal events
      setTimeout(() => {
        window?._moduleCCW?.CXMessenger.loadConversationHistory(data);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isLoading === false && showChat === true && channelInfo?.contactIdentifier) {
      loadConversationHistory({ ...channelInfo, ...appInfo });
    }
  }, [isLoading, showChat, channelInfo?.contactIdentifier]);

  const isAnonymous = !isValidEmail(channelInfo?.contactIdentifier);

  return (
    <div>
      <img
        src={`${CONSOLE_BASE_URL}/email-assets/plivo-logo.svg`}
        className="max-md:w-[120px] max-md:h-[30px] ml-10 mt-10"
        alt="Plivo AI"
      />
      <div className="flex flex-col items-center justify-center">
        <p className="text-gray-500">
          This is a copy of conversation between Plivo AI{' & '}
          {isAnonymous ? 'Anonymous user' : channelInfo?.contactIdentifier}
        </p>
      </div>
      <div
        id="demo-chat-container"
        className={clsx(
          'flex items-end justify-center max-lg:pt-[24px] max-lg:w-[100%] max-md:pt-[0px] transition-all ease-linear duration-500',
          'max-sm:grow sm:max-sm:items-stretch sm:max-sm:fixed sm:top-0 sm:left-0 sm:right-0 sm:bottom-0 sm:w-full sm:h-full'
        )}
      >
        <div
          className={clsx(
            'relative flex flex-col items-center justify-center h-[670px] w-[400px]',
            'max-sm:h-full max-sm:w-full'
          )}
        >
          <div
            className={clsx(
              'relative flex items-center justify-center shadow-bs3 rounded-[16px] bg-white chat-widget-overlay z-0 h-[630px] w-[360px]',
              'max-sm:w-full max-sm:h-full'
            )}
          >
            {isLoading && <AIAssistantLoader hideLoader={showChat} isLoading={isLoading} />}
          </div>
          <div
            id={CHAT_WIDGET_MOUNT_ID}
            className="absolute opacity-100 max-lg:rounded-[16px] w-full h-full"
            ref={chatContainerRef}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShareView;
