import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { Bot } from 'lucide-react';
import clsx from 'clsx';

const Loader = () => (
  <svg
    className="animate-spin h-12 w-12 text-blue-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {/* <circle cx="12" cy="12" r="10" /> */}
    {/* <path d="M12 2v10h10" /> */}
    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
  </svg>
);

export default Loader;

const AIAssistantLoader = ({ hideLoader, isLoading }) => (
  <AnimatePresence mode="wait">
    {!hideLoader && (
      <motion.div
        key="loader"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={clsx(
          'flex items-center space-x-2 bg-black text-white rounded-full px-4 py-2 shadow-lg w-44 z-10',
          'max-lg:bottom-[11.5rem] max-md:right-[3.5rem] max-md:bottom-[10rem] sm:max-sm:right-[5.5rem] sm:max-sm:bottom-[14rem]'
        )}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Bot className="w-6 h-6 text-white" />
        <span className="text-lg font-semibold">Plivo AI</span>
        {isLoading && (
          <motion.div
            className="flex space-x-1"
            initial="start"
            animate="end"
            variants={{
              start: { transition: { staggerChildren: 0.2 } },
              end: { transition: { staggerChildren: 0.2 } },
            }}
          >
            {[0, 1, 2].map((i) => (
              <motion.span
                key={i}
                className="w-2 h-2 bg-white rounded-full"
                variants={{
                  start: { y: 0 },
                  end: { y: [0, -6, 0] },
                }}
                transition={{ duration: 0.6, repeat: Infinity }}
              />
            ))}
          </motion.div>
        )}
      </motion.div>
    )}
  </AnimatePresence>
);

AIAssistantLoader.propTypes = {
  hideLoader: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export { AIAssistantLoader };
