/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AvatarWoman from '../../assets/avatar-woman.svg';
import AvatarMan from '../../assets/avatar-man.svg';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { AIAssistantLoader } from '../../components/loader';
import { CHAT_WIDGET_MOUNT_ID, CHAT_WIDGET_SCRIPT_TAG_ID, BYPASS_EMAIL_AUTH } from '../../config';
import { isMobileView, utf8ToBase64 } from '../../utils/helper';
import { getDemoQuestions } from '../../utils/service';
import { BubbleQuestionLoader } from './components';
import { ArrowRight, Share } from 'lucide-react';
import ShareChatModal from './components/shareChatModal';
import CalDotComPopup from '../../components/calDotComPopup';

const ChatApp = ({ chatScript, setToast }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
  const [chatState, setChatState] = useState({});
  const [isVirtualKeyboardOpen, setIsVirtualKeyboardOpen] = useState(false);
  const [isShareChatModalOpen, setIsShareChatModalOpen] = useState({show: false, url: ''});
  const [isCalDotComPopupOpen, setIsCalDotComPopupOpen] = useState(false);

  const windowDimensions = useRef({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [demoTextConfig, setDemoTextConfig] = useState({
    description: '',
    questions: [
      { text: 'How do I return my order?', avatar: AvatarMan },
      { text: 'Do you offer a discount if I promise to be your best customer ever?', avatar: AvatarWoman },
      { text: 'If I order 100 rubber ducks, will they arrive in a giant bathtub?', avatar: AvatarWoman },
    ]
  });

  const [isDemoTextConfigLoading, setIsDemoTextConfigLoading] = useState(true);

  useEffect(() => {
    if (chatScript === null) {
      setIsLoading(true);
    }
    const storedApp = localStorage.getItem('appInfo');
    if (!storedApp && !BYPASS_EMAIL_AUTH) {
      navigate('/' + location.search);
    }
  }, []);

  useEffect(() => {
    if (chatState?.pubnubInfo?.channelName) {
      const lastPubnubInfo = JSON.parse(localStorage.getItem('lastPubnubInfo'));
      if (lastPubnubInfo) {
        if (lastPubnubInfo.channelName !== chatState?.pubnubInfo?.channelName) {
          localStorage.setItem('lastPubnubInfo', JSON.stringify(chatState?.pubnubInfo));
        }
      } else {
        localStorage.setItem('lastPubnubInfo', JSON.stringify(chatState?.pubnubInfo));
      }
    }
  }, [chatState?.pubnubInfo]);

  useEffect(() => {
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    const handleVisualViewportChange = debounce(() => {
      if (window.visualViewport && isMobileView()) {
        const chatWindow = document.getElementsByClassName(
          'chat-widget-overlay'
        );
        if (!chatWindow.length) return;
        const currentViewportHeight = window.visualViewport.height;

        // If the viewport height is significantly smaller, assume keyboard is open
        if (windowDimensions.current.height - currentViewportHeight > 150) {
          setIsVirtualKeyboardOpen(true);
        } else {
          setIsVirtualKeyboardOpen(false);
        }
      }
    }, 100);

    if (window.visualViewport) {
      window.visualViewport.addEventListener(
        'resize',
        handleVisualViewportChange
      );
    }

    return () =>
      window.visualViewport.removeEventListener(
        'resize',
        handleVisualViewportChange
      );
  }, []);

  const getDemoData = async () => {
    const storedApp = localStorage.getItem('appInfo');
    if (!storedApp) {
      return;
    }
    const { app_id } = JSON.parse(storedApp);
    setIsDemoTextConfigLoading(true);
    getDemoQuestions(app_id, (response) => {
      const tempDemoQuestions = [ ...demoTextConfig.questions ]
      tempDemoQuestions.forEach((question, index) => {
        question.text = response.data.questions[index];
      });
      setDemoTextConfig({
        description: response.data.description,
        questions: tempDemoQuestions
      });
      setIsDemoTextConfigLoading(false);
    }, (error) => {
      console.error('Error fetching demo questions:', error);
      setIsDemoTextConfigLoading(false);
    });
  }

  useEffect(() => {
    if (!chatScript || document.getElementById(CHAT_WIDGET_SCRIPT_TAG_ID))
      return;

    getDemoData();
    console.log('Appending chat widget script to document head');
    const script = document.createElement('script');
    script.id = CHAT_WIDGET_SCRIPT_TAG_ID;
    script.innerHTML = chatScript;

    // Append the script to the document head
    document.head.appendChild(script);
    console.log('Script tag appended to head.');
  }, [chatScript]);

  const handleChatMessageEvent = (event) => {
    const { action, data } = event.data;

    switch (action) {
      case 'loaded':
        console.log('Chat widget loaded successfully');
        setIsLoading(false);
        setShowChat(true);
        break;
      case 'error':
        console.log('Chat widget failed to load: ', data.error);
        setToast({ show: true, message: data.error, type: 'error' });
        setIsLoading(false);
        setShowChat(true);
        break;
      case 'chatState':
        setChatState(data.chatState);
        break;
      default:
        break;
    }
  };  

  const onBubbleClickHandle = (text) => {
    if (
      window?._moduleCCW &&
      typeof window._moduleCCW?.CXMessenger?.quickMessage ===
        'function'
    ) {
      window?._moduleCCW?.CXMessenger.quickMessage(text);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleChatMessageEvent, false);
    return () => {
      window.removeEventListener('message', handleChatMessageEvent, false);
    };
  }, []);

  const onShareChatClick = async () => {
    const storedPubnubInfo = JSON.parse(localStorage.getItem('lastPubnubInfo'));
    const pubnubInfo = { 
      channelName: chatState?.pubnubInfo?.channelName || storedPubnubInfo?.channelName, 
      contactIdentifier: chatState?.pubnubInfo?.contactIdentifier || storedPubnubInfo?.contactIdentifier,
      grantToken: chatState?.pubnubInfo?.grantToken || storedPubnubInfo?.grantToken 
    }
    const encodehash = utf8ToBase64(JSON.stringify(pubnubInfo));
    const shareURL = `${window.location.origin}/demo/share/${encodehash}`;

    if (isMobileView() && navigator.share) { 
      try {
        await navigator.share({
          title: 'Plivo AI Chat',
          url: shareURL
        });
      } catch (error) {
        console.error('Error sharing chat:', error);
      }
    } else {
      setIsShareChatModalOpen({show: true, url: shareURL});
    }
  }

  // REM : Removing feature - appInfo should not be removed when again login is clicked instead it should be updated and hence on /chat we are not checking for chatScript
  //  if(!chatScript){
  //   return null;
  //  }

  const hideHeader = isVirtualKeyboardOpen && chatState.isConversationViewOpen;

  const  shareButtonBottomSheet = (cls) => {
    return    <div className={clsx('flex flex-row justify-between px-4 pt-4 pb-2 w-full z-[99] fit gap-4', 
      (chatState.isConversationViewOpen || !isVirtualKeyboardOpen || chatState.conversationState === 'active') ? 'max-sm:flex' : 'max-sm:hidden', cls)}>
       <button
          className={clsx(
            'font-poppins text-custom-buttom-14px leading-6 bg-custom-btn-bg px-4 py-3 max-md:px-3 rounded font-normal text-white',
            'hover:bg-custom-btn-bg-hover',
            'max-sm:h-9 max-sm:py-[6px]',
            'flex items-center justify-center space-x-2 flex-1 md:hidden',
            'hover:shadow-xl hover:scale-[1.02] bg-gradient-to-r from-emerald-500 to-teal-500 hover:from-emerald-600 hover:to-teal-600'
          )}
          type="button"
          onClick={() => setIsCalDotComPopupOpen(true)}>
           Hire Plivo AI
          <ArrowRight className='w-4 h-4 ml-2' />
      </button>
      <button className={clsx(
            'font-poppins text-custom-buttom-14px leading-6 px-4 py-3 max-md:px-3 rounded font-normal border-solid border-2',
            'hover:bg-custom-btn-bg-hover',
            'max-sm:h-9 max-sm:py-[6px]',
            'flex items-center space-x-2 flex-1 justify-center'
          )}
          type="button"
          onClick={onShareChatClick}
      >
        Share
        <Share className='w-4 h-4 ml-2' />
      </button>
    </div>
  }

  return (
    <div
      id="demo-chat-app"
      className="flex flex-col justify-between sm:max-sm:h-dvh min-md:min-h-screen bg-white h-full w-full"
    >
      {!hideHeader && <Header />}
      <main
        className={clsx(
          'flex flex-grow items-center justify-center gap-[235px] px-7',
          'max-sm:justify-start max-sm:items-center sm:max-sm:px-0 sm:max-sm:mt-0',
          isVirtualKeyboardOpen
            ? 'max-sm:pt-0 sm:max-sm:pt-0'
            : 'sm:max-sm:pt-[68px]',
          'max-md:gap-0 max-md:mt-[100px]',
          'max-lg:flex-wrap max-lg:px-[36px] max-lg:flex-col max-lg:mt-[24px] max-lg:gap-[24px]',
          'custom-xl:gap-[50px]'
        )}
      >
        <div
          className={clsx(
            'demo flex flex-col items-start font-poppins space-y-6 max-w-lg max-lg:items-center',
            'max-sm:space-y-2 max-sm:px-7 max-sm:mt-6 max-md:mb-[26px]',
            chatState.isConversationViewOpen ? 'max-sm:hidden' : 'max-sm:flex'
          )}
        >
          <h1
            className={clsx(
              'text-custom-heading font-bold leading-1.4 text-[40px] max-md:text-[24px]',
              'max-sm:leading-9'
            )}
          >
            Your demo is ready
          </h1>
          <div>
            <p className="text-custom-para font-normal max-md:text-[16px]">
              Go ahead, try to stump it!
            </p>
            <p className="hidden max-sm:block text-custom-para font-normal max-md:text-[16px]">
              It&apos;s kinda fun, we promise!
            </p>
          </div>
          {isDemoTextConfigLoading && !isMobileView() ?  
            <div className='animate-pulse w-96 h-10 bg-gray-100 rounded-lg'></div> : 
            <p className={clsx(`text-custom-para font-normal max-lg:text-center max-md:text-[16px] max-sm:hidden`)}>
              {demoTextConfig.description}
            </p>
          }
        
          <div className="space-y-4 z-10 max-lg:hidden">
            {isDemoTextConfigLoading ? 
              <BubbleQuestionLoader /> : 
              demoTextConfig.questions?.map((bubble) => ( 
              <button
                key={bubble.text}
                className="w-fit text-left flex items-center space-x-4 transition-colors"
                onClick={() => onBubbleClickHandle(bubble.text)}
              >
                <img
                  src={bubble.avatar}
                  className="rounded-full"
                  width="32"
                  alt="User avatar"
                />
                <span className="font-inter text-custom-span bg-gray-100 rounded-lg ml-custom-7 p-4 min-md:pr-custom-60 min-md:w-custom-276">
                  {bubble.text}
                </span>
              </button>
            ))}
          </div>
        </div>

        <div
          id="demo-chat-container"
          className={clsx(
            'flex items-end justify-center max-lg:pt-[24px] max-lg:w-[100%] max-md:pt-[0px] transition-all ease-linear duration-500',
            'max-sm:grow sm:max-sm:items-stretch relative'
          )}
        >
          <div
            className={clsx(
              'relative flex flex-col items-center justify-center h-[670px] w-[400px]',
              'max-sm:h-full max-sm:w-full'
            )}
          >
            <div
              className={clsx(
                'relative flex items-center justify-center shadow-bs3 rounded-[16px] bg-white chat-widget-overlay z-0 h-[630px] w-[360px]',
                'max-sm:w-full max-sm:h-full'
              )}
            >
              {isLoading && (
                <AIAssistantLoader
                  hideLoader={showChat}
                  isLoading={isLoading}
                />
              )}
            </div>
            <div
              id={CHAT_WIDGET_MOUNT_ID}
              className="absolute opacity-100 max-lg:rounded-[16px] w-full h-full"
              ref={chatContainerRef}
            />
          </div>
        </div>
          {(chatState?.pubnubInfo?.channelName || !!localStorage.getItem('lastPubnubInfo')) && shareButtonBottomSheet('md:hidden max-lg:visible')}
      </main>
      <Footer />
      <ShareChatModal 
        isOpen={isShareChatModalOpen.show} 
        onClose={() => setIsShareChatModalOpen({show: false, url: ''})} 
        url={isShareChatModalOpen.url}
        setToast={setToast} />
        {(chatState?.pubnubInfo?.channelName || !!localStorage.getItem('lastPubnubInfo')) &&<button className={clsx(
            'fixed bottom-10 right-8 bg-white font-poppins text-custom-buttom-14px leading-6 px-4 py-3 max-md:px-3 rounded font-normal border-solid border-2',
            'hover:bg-custom-btn-bg-hover',
            'max-sm:h-9 max-sm:py-[6px]',
            'flex items-center space-x-2 flex-1 justify-center max-lg:hidden max-sm:hidden'
          )}
          type="button"
          onClick={onShareChatClick}
        >
        Share
        <Share className='w-4 h-4 ml-2' />
      </button>}
      <CalDotComPopup
        isOpen={isCalDotComPopupOpen}
        onClose={() => setIsCalDotComPopupOpen(false)}
      />
    </div>
  );
};

ChatApp.propTypes = {
  chatScript: PropTypes.string,
};

ChatApp.defaultProps = {
  chatScript: null,
};

export default ChatApp;
