import React from 'react';
import PropTypes from 'prop-types';
import { Mail, Copy } from 'lucide-react';
import SheetModal from '../../../components/sheetModal';
import SlackIcon from '../../../assets/slack.png';
import WhatsAppIcon from '../../../assets/whatsapp.png';

// Constant for Share Options
const SHARE_OPTIONS = [
  {
    name: 'Copy',
    type: 'LINK',
    icon: <Copy size={16} className="text-black-500" />,
  },
  {
    name: 'Email',
    type: 'EMAIL',
    icon: <Mail size={16} className="text-red-500" />,
  },
  {
    name: 'Slack',
    type: 'SLACK',
    icon: <img src={SlackIcon} alt="Slack" height={16} width={16} />,
  },
  {
    name: 'WhatsApp',
    type: 'WHATSAPP',
    icon: <img src={WhatsAppIcon} alt="WhatsApp" height={18} width={18} />,
  },
];

const ShareChatModal = ({ isOpen, onClose, url, setToast }) => {
  // Fallback copy to clipboard
  function copyToClipboardFallback(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  function copyToClipboard(text) {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        setToast({ show: true, message: 'Link Copied', type: 'success' });
      } else {
        // Fallback for unsupported browsers
        copyToClipboardFallback(text);
        setToast({ show: true, message: 'Link Copied', type: 'success' });
      }
    } catch (error) {
      setToast({ show: true, message: 'Failed to copy link', type: 'error' });
    }
  }

  const handleShare = (type, text) => {
    switch (type) {
      case 'LINK':
        copyToClipboard(text);
        break;
      case 'EMAIL': {
        const subject = encodeURIComponent(
          'Check out this conversation with Plivo AI'
        );
        const body = encodeURIComponent(text);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
        break;
      }
      case 'SLACK': {
        copyToClipboard(text);
        const slackLink = `slack://user?text=${encodeURIComponent(text)}`;
        window.location.href = slackLink;
        break;
      }
      case 'WHATSAPP': {
        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(text)}`;
        window.open(whatsappLink, '_blank');
        break;
      }
      default:
        break;
    }
  };

  return (
    <SheetModal isOpen={isOpen} onClose={onClose} title="Share">
      <div className="text-xs font-medium mb-1.5">Page Link</div>

      <div className="relative flex justify-between items-center px-3 py-2 rounded-md border-gray-300 border">
        <div className="truncate text-xs font-normal text-slate-400">{url}</div>
      </div>

      <div className="flex justify-between mt-6 flex-wrap flex-row grid grid-cols-2 gap-4">
        {SHARE_OPTIONS.map((option) => (
          <button
            type="button"
            key={option.name}
            className="cursor-pointer box-shadow-sm rounded-md border border-gray-300 p-2 flex flex-row items-center justify-center hover:bg-gray-100"
            onClick={() => handleShare(option.type, url)}
          >
            <div className="flex justify-center items-center">
              {option.icon}
            </div>
            <div className="text-sm  font-light text-center ml-2">
              {option.name}
            </div>
          </button>
        ))}
      </div>
    </SheetModal>
  );
};

ShareChatModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
};

ShareChatModal.defaultProps = {
  url: '',
};

export default ShareChatModal;
