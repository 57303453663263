import React from 'react';
import clsx from 'clsx';
import PlivoAIDemo from '../assets/plivo-demo.svg';

export default function Footer({ isLoginPage }) {
  return (
    <footer
      className={clsx(
        'footer flex items-end text-xs text-gray-500 w-full box-border bg-white pl-[24px] pb-[26px]',
        'max-sm:flex-col max-sm:items-start',
        !isLoginPage && 'max-sm:hidden',
        'max-md:border-t-1 max-md:border-t-custom-border max-md:pt-[24px] max-md:mt-[40px]',
        'lg:sticky max-lg:pt-[40px]'
      )}
    >
      <img
        src={PlivoAIDemo}
        alt="Plivo AI Demo"
        className="pr-8 max-sm:pb-4  max-sm:pt-3"
      />
      <div className="font-poppins text-custom-disclaimer font-normal px-[16.5%] pl-[calc(16.5%-250px)] xl:w-full xl:text-center">
        Disclaimer: This demo is for testing only, may be inaccurate or
        unreliable. Plivo provides it AS-IS without warranties of any kind and
        can stop access anytime.
      </div>
    </footer>
  );
}
