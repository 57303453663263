import React, { useState } from 'react';
import AvatarMan from '../../../assets/avatar-man.svg';
import AvatarWoman from '../../../assets/avatar-woman.svg';

const BubbleQuestionLoader = () => {
  const [demoQuestions, setDemoQuestions] = useState([
    { text: '', avatar: AvatarMan },
    { text: '', avatar: AvatarWoman },
    { text: '', avatar: AvatarWoman },
  ]);
  return demoQuestions.map((bubble) => (
    <button
      type="button"
      className="w-fit text-left flex items-center space-x-4 transition-colors"
    >
      <img
        src={bubble.avatar}
        className="rounded-full"
        width="32"
        alt="User avatar"
      />
      <span className="font-inter text-custom-span bg-gray-100 rounded-lg ml-custom-7 p-4 min-md:pr-custom-60 min-md:w-custom-276 h-20">
        {bubble.text}
      </span>
    </button>
  ));
};

export default React.memo(BubbleQuestionLoader);
