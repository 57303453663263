import axios from 'axios';
import { FETCH_APP_INFO_URL, FETCH_DEMO_QUESTIONS_URL } from '../config';

export const getDemoQuestions = async (appId, onSuccess, onError) => {
  try {
    const response = await axios.get(FETCH_DEMO_QUESTIONS_URL(appId));
    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (onError && typeof onError === 'function') {
      onError(error);
    }
    throw error;
  }
};

export const getAppInfo = async (email, onSuccess, onError) => {
  try {
    const response = await axios.post(FETCH_APP_INFO_URL, {
      email,
      hostname: window.location.origin,
    });
    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess(response);
    }
    return response.data;
  } catch (error) {
    if (onError && typeof onError === 'function') {
      onError(error);
    }
    throw error;
  }
};
