import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Cal from '@calcom/embed-react';
import posthog from 'posthog-js';
import { isMobileView } from '../utils/helper';

Modal.setAppElement('#root');

const CalDotComPopup = ({ isOpen, onClose }) => {
  const isMobile = isMobileView();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const customStyles = {
    content: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      padding: isMobile ? '0px' : '20px',
      borderRadius: '8px',
      border: 'none',
      maxHeight: '90vh',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 999,
    },
  };

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'auto';
    posthog.capture('modal_closed');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Calendar Modal"
      onOverlayClick={handleClose}
    >
      <div className="w-full h-full relative">
        <div className=" max-sm:pb-0 max-sm:pt-2 flex justify-end">
          <button
            onClick={handleClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors z-[99999999999999] text-white hover:text-black max-sm:mb-2"
            aria-label="Close modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-inherit hover:text-black transition-colors"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <Cal
          calLink="plivoai/30min"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          config={{
            theme: 'light',
          }}
        />
      </div>
    </Modal>
  );
};

export default CalDotComPopup;
